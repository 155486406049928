import AxiosClient from "./axiosClient";

const ConversationApi = {
    getListConversation: (params) => {
        const url = "/conversation/list_by_time"
        return AxiosClient.get(url, {params})
    },
    getListConversationV2: (params) => {
        const url = "/conversation/list_by_time"
        return AxiosClient.get(url, {
            params,
            baseURL: process.env.REACT_APP_BASE_URL_V2
        })
    },
    getConversation: (params) => {
        const url = "/conversation"
        return AxiosClient.get(url, {params})
    },
    evaluate: (params) => {
        const url = "/conversation/evaluate"
        return AxiosClient.post(url, params)
    },
    evaluateV2: (params) => {
        const url = "/conversation/evaluate"
        return AxiosClient.post(url, params, {baseURL: process.env.REACT_APP_BASE_URL_V2})
    }
    ,
    updateCustomer: (params) => {
        const url = "/conversation/update-customer"
        return AxiosClient.put(url, params)
    },
    overview: (params) => {
        const url = "/conversation/overview"
        return AxiosClient.get(url, {params})
    },
    chatByTime: (params) => {
        const url = "/conversation/chat_by_time"
        return AxiosClient.get(url, {params})
    },
    conversationByDay: (params) => {
        const url = "/conversation/conversation-by-day"
        return AxiosClient.get(url, {params})
    },
}
export default ConversationApi