import {endOfDay, startOfDay, subDays} from "date-fns";

const AppConstant = {
    pageMessage: 50,
    minLoad: 10,
    splitPageMessageTime: 2 * 60 * 60 * 1000, // milliseconds,
    timeRefreshToken: 2 * 60 * 60 * 1000, // milliseconds
    characterSplit: ","
}

export const PLATFORM = {
    website: "website",
    facebook: "facebook",
    zalo: "zalo",
    shopee: "shopee",
    tiktok: "tiktok",
    app: "app"
}

export const ConversationStatus = {
    normal: "normal",
    waiting: "waiting",
    processing: "processing",
    processed: "processed",
}

export const messageType = {
    text: "text",
    voice: "voice",
    emoji: "emoji",
    file: "file",
}

export const MESSAGE_TYPE = {
    text: "text",
    audio: "audio",
    video: "video",
    image: "image",
    file: "file",
}

export const initTime = {
    startDate: subDays(new Date(), 6),
    endDate: new Date()
}

export const CHART_DATETIME_PICKER_INIT = {
    from: startOfDay(subDays(new Date(), 6)),
    to: endOfDay(new Date())
}

export const DATETIME_PICKER_INIT = {
    from: startOfDay(new Date()),
    to: endOfDay(new Date())
}

export const roleMember = {
    owner: "owner",
    admin: "admin",
    chatAgent: "chatAgent",
    tester: "tester"
}

export const statusCustomer = {
    available: "available",
    unavailable: "unavailable",
    invisible: "invisible",
    away: "away",
    dnd: "dnd",
    eager: "eager"
}

export const statusMember = {
    accepted: "accepted",
    rejected: "rejected",
    pending: "pending",
    locked: "locked"
}

export const MESSAGEAGENT = {
    customer: "customer",
    account: "account",
    botAI: "botAI"
}

// seconds
export const PERIODIC_TIME = {
    oneDay: 24 * 60 * 60,
    sevenDays: 7 * 24 * 60 * 60,
    fifteenDays: 15 * 24 * 60 * 60,
    thirtyDays: 30 * 24 * 60 * 60,
    threeMonths: 90 * 24 * 60 * 60,
    oneYear: 365 * 24 * 60 * 60,
}

export const PLAN_TYPE = {
    periodic: "periodic",
    usage: "usage"
}

export const SOCKET_EVENT = {
    message: "message",
    customerMessage: "message/customer",
    accountMessage: "message/account",
    botMessage: "message/bot",
    botStatus: "status",
    controlBot: "control",
    readMessage: "read_message",
    chatDirect: "chat_direct",
    statusConversations: "status_conversations",
    handOverConversation: 'hand_over_conversation',
    requestHandOverConversation: 'request_hand_over_conversation',
    rejectHandOverConversation: 'reject_hand_over_conversation',
    FINISH_SESSION: 'FINISH_SESSION',
    PING: "PING",
    REQUEST_DIRECT_ACCOUNT: 'REQUEST_DIRECT_ACCOUNT',
    CHANGE_STATUS_CONVERSATION: 'CHANGE_STATUS_CONVERSATION',
    EXPIRED_REQUEST_DIRECT: 'EXPIRED_REQUEST_DIRECT',
    REQUEST_SUPPORT: 'REQUEST_SUPPORT',
}

export const SIZE_SCREEN = {
    sx: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    "2xl": 1536
}
export const ACCOUNT_ROLE = {
    user: "user",
    admin: "admin",
}

export const WINDOW_MESSAGE = {
    topUpSuccessfully: "topUpSuccessfully",
    topUpCancelled: "topUpCancelled"
}

export const APP_LANGUAGE = {
    vi: "vi",
    en: "en"
}

export const NOTIFICATION_PERMISSION_STATUS = {
    granted: "granted",
    denied: "denied",
    default: "default"
}

export const CONVERSATION_STATUS = {
    normal: "normal",
    waiting: "waiting",
    processing: "processing",
    processed: "processed",
}

export const APP_TIME = {
    debounce: 1000 // milliseconds
}

export const STATUS_INDEX = {
    ALL: 0,
    WAITING: 1,
    PROCESSING: 2,
    WAITING_AND_PROCESSING: 3
}

export const SOCKET_CONFIG = {
    ATTEMPT: 3,
    TIME_PING: 30 * 1000 // milliseconds
}

export const TOAST_CONFIG = {
    SOCKET_DISCONNECT_ID: 1000,
    RUN_OUT_OF_TOKEN_ID: 1001
}

export const TYPE_FILE_EXPORT = {
    EXCEL: 'excel',
    CSV: 'csv'
}

export const BOT_SYSTEM_STATUS = {
    OK: "OK",
    NOK: "NOK"
}

export const NUANCE = {
    POSITIVE: "POSITIVE",
    NEUTRAL: "NEUTRAL",
    NEGATIVE: "NEGATIVE"
}

export const CATEGORY = {
    PLAN: "PLAN",
    PROMOTION_AND_CARE: "PROMOTION_AND_CARE",
    PROFESSTIONAL: "PROFESSTIONAL",
    INTERNATION_ROAMING: "INTERNATION_ROAMING",
    PRODUCT_AND_SERVICE: "PRODUCT_AND_SERVICE",
    OTHER: "OTHER"
}
export const topicLink = process.env.REACT_APP_URL_SOCKET_IO.replace("/conversation", "")

export default AppConstant